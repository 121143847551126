import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { API } from '@/api';

const state: ListsState = {
	isLoaded: false,
	isPending: false,
	data: {
		campaigns: [],
		clients: [],
		measurementTypes: [],
		salesPersons: [],
		webs: [],
	},
	error: undefined,
};

export const getters: GetterTree<ListsState, RootState> = {
	allData: (state): ListsData => state.data,
	isLoaded: (state): boolean => state.isLoaded,
	isPending: (state): boolean => state.isPending,
	error: (state): string | undefined => state.error,
};

export const actions: ActionTree<ListsState, RootState> = {
	fetchData: async ({ commit, dispatch }, force: boolean): Promise<boolean> => {
		if (state.isPending) {
			return true;
		}
		if (state.isLoaded && !force) {
			return true;
		}
		const idPending = await dispatch('pending/add', null, { root: true });
		commit('setIsPending');
		try {
			const measurementTypesResponse = await API.lists.getMeasurementTypesList();
			const campaignsResponse = await API.lists.getCampaignsList();
			const clientsResponse = await API.lists.getClientsList();
			const salesPersonsResponse = await API.lists.getSalesPersonsList();
			const websResponse = await API.lists.getWebsList();

			const measurementTypes = Array.isArray(measurementTypesResponse?.payload)
				? measurementTypesResponse?.payload
				: [];
			const campaigns = Array.isArray(campaignsResponse?.payload) ? campaignsResponse?.payload : [];
			const clients = Array.isArray(clientsResponse?.payload) ? clientsResponse?.payload : [];
			const webs = Array.isArray(websResponse?.payload) ? websResponse?.payload : [];
			const salesPersons = Array.isArray(salesPersonsResponse?.payload)
				? salesPersonsResponse?.payload
				: [];

			const data: ListsData = {
				campaigns,
				clients,
				measurementTypes,
				salesPersons,
				webs,
			};
			commit('setData', data);
			commit('setIsLoaded');
			commit('unsetIsPending');
			await dispatch('pending/remove', idPending, { root: true });
			return true;
		} catch (errorMessage) {
			commit('setError', errorMessage || 'Neznámá chyba při načtení číselníků');
			commit('unsetIsPending');
			await dispatch('pending/remove', idPending, { root: true });
			return true;
		}
	},
	fetchSalesPersons: async ({ commit, dispatch }, force: boolean): Promise<boolean> => {
		if (state.isPending) {
			return true;
		}
		if (state.isLoaded && !force) {
			return true;
		}
		const idPending = await dispatch('pending/add', null, { root: true });
		commit('setIsPending');
		try {
			const salesPersonsResponse = await API.lists.getSalesPersonsList();

			const salesPersons = Array.isArray(salesPersonsResponse?.payload)
				? salesPersonsResponse?.payload
				: [];

			commit('setSalesPersons', salesPersons);
			commit('setIsLoaded');
			commit('unsetIsPending');
			await dispatch('pending/remove', idPending, { root: true });
			return true;
		} catch (errorMessage) {
			commit('setError', errorMessage || 'Neznámá chyba při načtení číselníku');
			commit('unsetIsPending');
			await dispatch('pending/remove', idPending, { root: true });
			return true;
		}
	},
};

export const mutations: MutationTree<ListsState> = {
	setData(state, data: ListsData) {
		state.data = data;
	},
	setSalesPersons(state, data: List[]) {
		state.data.salesPersons = data;
	},
	unsetData(state) {
		state.data = { campaigns: [], clients: [], measurementTypes: [], salesPersons: [], webs: [] };
	},
	setIsPending(state) {
		state.isPending = true;
	},
	unsetIsPending(state) {
		state.isPending = false;
	},
	setIsLoaded(state) {
		state.isLoaded = true;
	},
	unsetIsLoaded(state) {
		state.isLoaded = false;
	},
	setError(state, error: string) {
		state.error = error;
	},
	unsetError(state) {
		state.error = undefined;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
