import { TOKEN_NAME } from '@/constants';

export const useAuth = () => {
	const getToken = () => localStorage.getItem(TOKEN_NAME) || null;

	const getAuthHeaders = (): Record<string, string> => {
		const token = getToken();

		if (!token) return {};

		return {
			authorization: `Bearer ${token}`,
		};
	};
	return {
		getToken,
		getAuthHeaders,
	};
};
