import { ajax, ajaxUnauthorized } from '@/utils';
import { Method } from '@/types';

interface LoginDto {
	status: string;
	payload: {
		token?: string;
		message?: string;
		fields?: AjaxResponseFieldsDto;
	};
}
const login = async (authData: AuthInput): Promise<LoginDto> =>
	ajaxUnauthorized<LoginDto>(Method.Post, '/login', authData);
const forgottenPassword = async (username: string): Promise<AjaxResponseDto> =>
	ajaxUnauthorized<AjaxResponseDto>(Method.Post, '/forgotten-password', { username });
const createNewPassword = async (props: NewPasswordInput): Promise<AjaxResponseDto> =>
	ajaxUnauthorized<AjaxResponseDto>(Method.Post, '/create-new-password', props);

const logout = async (): Promise<AjaxResponseDto> => ajax<AjaxResponseDto>(Method.Post, '/logout');
const checkLogin = async (): Promise<AjaxResponseDto> =>
	ajax<AjaxResponseDto>(Method.Post, '/check-login');

export default {
	login,
	logout,
	checkLogin,
	forgottenPassword,
	createNewPassword,
};
