import { ajax } from '@/utils';
import { Method } from '@/types';

type ListDto = List;

declare global {
	interface ListResponseDto {
		status: AjaxResponseStatus;
		payload: ListDto[] | MessageResponseDto;
	}
}
const getClientsList = async (): Promise<ListResponseDto> =>
	ajax<ListResponseDto>(Method.Get, `/lists/clients`);
const getCampaignsList = async (): Promise<ListResponseDto> =>
	ajax<ListResponseDto>(Method.Get, `/lists/campaigns`);
const getMeasurementTypesList = async (): Promise<ListResponseDto> =>
	ajax<ListResponseDto>(Method.Get, `/lists/article-types`);
const getSalesPersonsList = async (): Promise<ListResponseDto> =>
	ajax<ListResponseDto>(Method.Get, `/lists/sales-persons`);
const getWebsList = async (): Promise<ListResponseDto> =>
	ajax<ListResponseDto>(Method.Get, `/lists/webs`);

export default {
	getClientsList,
	getCampaignsList,
	getMeasurementTypesList,
	getSalesPersonsList,
	getWebsList,
};
