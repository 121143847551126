import { createStore } from 'vuex';
import auth from './authorization';
import pending from './pending';
import users from './users';
import measurement from './measurement';
import lists from './lists';

export const modules = {
	auth,
	pending,
	users,
	measurement,
	lists,
};

export default createStore({
	modules,
});
