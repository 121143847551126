import auth from './authorization';
import users from './users';
import myAccount from './myAccount';
import measurement from './measurement';
import lists from './lists';
import apiSettings from './apiSettings';

export const API = {
	auth,
	users,
	myAccount,
	measurement,
	lists,
	apiSettings,
};
