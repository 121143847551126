import { $fetch } from 'ohmyfetch';
import { v4 as uuidV4 } from 'uuid';
import { makeId } from '@/utils';
import { BASE_URL } from '@/constants';
import { useAuth } from '@/composables/useAuth';

const getApiKeys = async () => {
	return $fetch<ApiKeys.GetApiKeys.Response>(`${BASE_URL}/api-key`, {
		headers: {
			...useAuth().getAuthHeaders(),
		},
	});
};

const removeApiKey = async (id: string) =>
	$fetch(`${BASE_URL}/api-key/${id}`, {
		method: 'DELETE',
		headers: {
			...useAuth().getAuthHeaders(),
		},
	});

const createApiKey = async (name: string) =>
	$fetch(`${BASE_URL}/api-key`, {
		method: 'POST',
		body: {name},
		headers: {
			...useAuth().getAuthHeaders(),
		},
	});

// let demoTokens: ApiKey[] = [
// 	{
// 		id: '401b1e0a-a02f-5138-be87-32f1a3adc3ce',
// 		name: 'victory',
// 		token: 'D0SHrXUr9MnaVOZ1iZu1a7GdzeInxkHqs5Dd0BcPsHrCCGI73MVvwGI',
// 		created_at: '2022-02-05T23:42:08Z',
// 	},
// 	{
// 		id: 'bf00566d-5086-5683-8938-0228dd3e694e',
// 		name: 'chose',
// 		token: 'xtv7b90ufnUp0hWSYsg3rEk5BTRXlsK9tZEy6UAmrp9igZS5n3s2zRS',
// 		created_at: '2022-10-17T12:31:18Z',
// 	},
// 	{
// 		id: '6c16f68a-2746-58a2-a181-489d1ff3bba9',
// 		name: 'series',
// 		token: 'kNWnOFrS5wH580nZlGeMM5joxuuawq2MqHCJHiYrpncKVainFaomJOB',
// 		created_at: '2022-12-09T04:46:56Z',
// 	},
// ];

// const getApiKeys = () => {
// 	return Promise.resolve({ payload: demoTokens });
// };

// const removeApiKey = (id: string) => {
// 	demoTokens = demoTokens.filter((token) => token.id !== id);

// 	return Promise.resolve({ payload: null, status: 'ok' });
// };

// const createApiKey = async (name: string) => {
// 	const newToken: ApiKey = {
// 		id: uuidV4(),
// 		name,
// 		token: makeId(68, ['char', 'number']).toString(),
// 		created_at: new Date().toISOString(),
// 	};

// 	demoTokens.push(newToken);

// 	return Promise.resolve({ payload: null, status: 'ok' });
// };

export default {
	createApiKey,
	removeApiKey,
	getApiKeys,
};
