import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { API } from '@/api';
import { SelectMixedOption } from 'naive-ui/lib/select/src/interface';

const state: UsersState = {
	isLoaded: false,
	isPending: false,
	data: [],
	error: undefined,
};

export const getters: GetterTree<UsersState, RootState> = {
	allUsers: (state): User[] => state.data,
	salesPersonsOptions: (state): SelectMixedOption[] => {
		return state.data.map((item) => {
			const output: SelectMixedOption = {
				value: item.id,
				label: [item.first_name, item.last_name].filter((val) => !!val).join(' '),
				disabled: item.roles.indexOf('sales_person') === -1,
			};

			return output;
		});
	},
	isLoaded: (state): boolean => state.isLoaded,
	isPending: (state): boolean => state.isPending,
	error: (state): string | undefined => state.error,
};

export const actions: ActionTree<UsersState, RootState> = {
	fetchData: async ({ commit }, force: boolean): Promise<boolean> => {
		if (state.isLoaded && !force) {
			return true;
		}
		commit('setIsPending');
		try {
			const { payload, status }: UsersResponseDto = await API.users.getUsers();
			if (status === 'ok') {
				if (typeof payload !== 'undefined' && Array.isArray(payload)) {
					commit('setUsers', payload);
					commit('setIsLoaded');
				}
			} else {
				commit(
					'setError',
					!Array.isArray(payload)
						? payload?.message || 'Neznámá chyba při načtení uživatelů'
						: 'Neznámá chyba při načtení uživatelů',
				);
			}
			commit('unsetIsPending');
			return true;
		} catch (errorMessage) {
			commit('setError', errorMessage || 'Neznámá chyba při načtení uživatelů');
			commit('unsetIsPending');
			return true;
		}
	},
};

export const mutations: MutationTree<UsersState> = {
	setUsers(state, data: User[]) {
		state.data = data;
	},
	unsetUsers(state) {
		state.data = [];
	},
	setIsPending(state) {
		state.isPending = true;
	},
	unsetIsPending(state) {
		state.isPending = false;
	},
	setIsLoaded(state) {
		state.isLoaded = true;
	},
	unsetIsLoaded(state) {
		state.isLoaded = false;
	},
	setError(state, error: string) {
		state.error = error;
	},
	unsetError(state) {
		state.error = undefined;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
