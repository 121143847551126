import { ajax } from '@/utils';
import { Method } from '@/types';

type UserDto = User;

declare global {
	interface UserResponseDto {
		status: AjaxResponseStatus;
		payload: UserDto | MessageResponseDto;
	}

	interface UsersResponseDto {
		status: AjaxResponseStatus;
		payload: UserDto[] | MessageResponseDto;
	}
}
const getUser = async (id: string): Promise<UserResponseDto> =>
	ajax<UserResponseDto>(Method.Get, `/user/${id}`);

const getUsers = async (): Promise<UsersResponseDto> =>
	ajax<UsersResponseDto>(Method.Get, '/users');

const resetPassword = async (id: string): Promise<AjaxResponseDto> =>
	ajax<AjaxResponseDto>(Method.Post, `/user/${id}/reset-password`);

const enableLogin = async (id: string): Promise<AjaxResponseDto> =>
	ajax<AjaxResponseDto>(Method.Post, `/user/${id}/can-login`);

const disableLogin = async (id: string): Promise<AjaxResponseDto> =>
	ajax<AjaxResponseDto>(Method.Delete, `/user/${id}/can-login`);

const setRoles = async (id: string, roles: string[]): Promise<AjaxResponseDto> =>
	ajax<AjaxResponseDto>(Method.Put, `/user/${id}/roles`, { roles });

const updateUser = async (
	id: string,
	formData: {
		first_name: string;
		last_name: string;
		username: string;
	},
): Promise<AjaxResponseDto> => ajax<AjaxResponseDto>(Method.Put, `/user/${id}`, formData);

const deleteUser = async (id: string): Promise<AjaxResponseDto> =>
	ajax<AjaxResponseDto>(Method.Delete, `/user/${id}`);

const createNewUser = async (formData: {
	first_name: string;
	last_name: string;
	username: string;
	can_login: boolean;
	no_send_email: boolean;
}): Promise<UserResponseDto> => ajax<UserResponseDto>(Method.Post, '/user', formData);

export default {
	getUser,
	getUsers,
	setRoles,
	updateUser,
	createNewUser,
	enableLogin,
	disableLogin,
	resetPassword,
	deleteUser,
};
