import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { Method } from '@/types';
import { BASE_URL, TIMEOUT, TOKEN_NAME } from '@/constants';

const ajaxCall = <Output extends unknown>(config: AxiosRequestConfig): Promise<Output> =>
	new Promise((resolve, reject) => {
		axios(config)
			.then((response: AxiosResponse): void => {
				if (typeof response?.data === 'undefined') {
					// eslint-disable-next-line prefer-promise-reject-errors
					reject({ status: 'error', payload: { message: 'Missing response data' } });
				} else {
					resolve(response?.data);
				}
			})
			.catch((error: AxiosError): void => {
				if (error.response) {
					// The request was made and the server responded with a status code
					// that falls out of the range of 2xx
					if (error.response.status === 401) {
						if (localStorage.getItem(TOKEN_NAME)) {
							localStorage.removeItem(TOKEN_NAME);
							window.location.href = '/';
						}
					}
					// eslint-disable-next-line prefer-promise-reject-errors
					reject({
						status: 'error',
						payload: {
							code: error?.response?.status || 400,
							message: error.response?.data?.payload?.message || 'Neznámá chyba',
						},
					});
				} else if (error.request) {
					// The request was made but no response was received
					// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
					// http.ClientRequest in node.js
					// eslint-disable-next-line prefer-promise-reject-errors
					reject({ status: 'error', payload: { message: 'No response received' } });
				} else {
					// Something happened in setting up the request that triggered an Error
					// eslint-disable-next-line prefer-promise-reject-errors
					reject({ status: 'error', payload: { message: error.message } });
				}
			});
	});

export const ajax = <Output extends unknown>(
	method: Method,
	url: string,
	data?: unknown | undefined
): Promise<Output> => {
	const token: string | null = localStorage.getItem(TOKEN_NAME);
	if (!token) {
		// eslint-disable-next-line prefer-promise-reject-errors
		return Promise.reject({ status: 'error', payload: { message: 'Chybějící token' } });
	}
	const config: AxiosRequestConfig = {
		baseURL: BASE_URL,
		method,
		url,
		timeout: TIMEOUT,
		headers: {
			authorization: `Bearer ${token}`,
		},
	};

	if (method === Method.Get) {
		config.params = data;
	}
	if (method === Method.Post || method === Method.Put) {
		config.data = data;
	}

	return ajaxCall(config);
};
export const ajaxUnauthorized = <Output extends unknown>(
	method: Method,
	url: string,
	data?: unknown | undefined,
	options?: AxiosRequestConfig
): Promise<Output> => {
	const config: AxiosRequestConfig = {
		baseURL: BASE_URL,
		method,
		url,
		timeout: TIMEOUT,
	};

	if (method === Method.Get) {
		config.params = data;
	}
	if (method === Method.Post || method === Method.Put) {
		config.data = data;
	}

	return ajaxCall<Output>(config);
};
