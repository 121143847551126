import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import HomePage from '@/pages/authorized/HomePage.vue';

const routes: Array<RouteRecordRaw> = [
	{
		path: '/',
		name: 'HomePage',
		component: HomePage,
	},
	{
		path: '/zapomenute-heslo',
		name: 'ForgottenPasswordPage',
		component: () =>
			import(
				/* webpackChunkName: "ForgottenPasswordPage" */ '@/pages/unauthorized/ForgottenPasswordPage.vue'
			),
	},

	{
		path: '/nove-heslo/:request_id',
		name: 'NewPasswordPage',
		component: () =>
			import(/* webpackChunkName: "NewPasswordPage" */ '@/pages/unauthorized/NewPasswordPage.vue'),
	},
	{
		path: '/muj-ucet',
		name: 'MyAccountPage',
		component: () =>
			import(/* webpackChunkName: "MyAccountPage" */ '@/pages/authorized/MyAccountPage.vue'),
	},
	{
		path: '/mereni',
		name: 'MeasurementPage',
		redirect: '/mereni/prehled',
		component: () =>
			import(
				/* webpackChunkName: "MeasurementPage" */ '@/pages/authorized/Measurement/MeasurementPage.vue'
			),
		children: [
			{
				path: 'prehled',
				name: 'MeasurementListPage',
				component: () =>
					import(
						/* webpackChunkName: "MeasurementListPage" */ '@/pages/authorized/Measurement/MeasurementListPage.vue'
					),
			},
			{
				path: 'nove-mereni',
				name: 'NewMeasurementPage',
				component: () =>
					import(
						/* webpackChunkName: "NewMeasurementPage" */ '@/pages/authorized/Measurement/NewMeasurementPage.vue'
					),
			},
			{
				path: 'detail/:id',
				name: 'DetailMeasurementPage',
				component: () =>
					import(
						/* webpackChunkName: "DetailMeasurementPage" */ '@/pages/authorized/Measurement/DetailMeasurementPage.vue'
					),
			},
		],
	},
	{
		path: '/nastaveni',
		name: 'SettingsPage',
		redirect: '/nastaveni/uzivatele',
		component: () =>
			import(/* webpackChunkName: "SettingsPage" */ '@/pages/authorized/Settings/SettingsPage.vue'),
		children: [
			{
				path: 'novy-uzivatel',
				name: 'CreateNewUserPage',
				component: () =>
					import(
						/* webpackChunkName: "CreateNewUserPage" */ '@/pages/authorized/Settings/User/CreateNewUserPage.vue'
					),
			},
			{
				path: 'uzivatel/:id',
				name: 'UserDetailPage',
				component: () =>
					import(
						/* webpackChunkName: "UserDetailPage" */ '@/pages/authorized/Settings/User/UserDetailPage.vue'
					),
			},
			{
				path: 'uzivatele',
				name: 'UsersListPage',
				component: () =>
					import(
						/* webpackChunkName: "UsersListPage" */ '@/pages/authorized/Settings/User/UsersListPage.vue'
					),
			},
			{
				path: 'api',
				name: 'ExternalApiSettings',
				component: () =>
					import(
						/* webpackChunkName: "ExternalApiSettings" */ '@/pages/authorized/Settings/ExternalApiSettings.vue'
					),
			},
		],
	},
	{
		path: '/:catchAll(.*)',
		name: 'NotFoundPage',
		component: () =>
			import(/* webpackChunkName: "NotFoundPage" */ '@/pages/unauthorized/NotFoundPage.vue'),
	},
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
});

export default router;
