import { JwtPayload } from 'jwt-decode';
import { TableBaseColumn } from 'naive-ui/lib/data-table/src/interface';

declare global {
	interface Window {
		// eslint-disable-next-line
		$message: any;
	}
	interface TextInputTableBaseColumn extends TableBaseColumn {
		filterOptionValue: string | null;
	}
	interface MenuItem {
		id: string;
		to?: string;
		onClick?: () => void;
		label?: string;
		icon?: string;
		isActive: boolean;
		isDesktopMain?: boolean;
		isDesktopRightSide?: boolean;
		isHideLabelDesktop?: boolean;
		isMobile?: boolean;
		isHideOnMobile?: boolean;
		isHideOnDesktop?: boolean;
		isAuthorized?: boolean;
	}

	interface AjaxResponseFieldsDto {
		[n: string]: string;
	}
	interface MessageResponseDto {
		code?: number;
		message?: string;
		fields?: AjaxResponseFieldsDto;
	}
	type AjaxResponseStatus = 'ok' | 'error';

	interface AjaxResponseDto {
		status: AjaxResponseStatus;
		payload:
			| MessageResponseDto
			| []
			| null
			| { [n: string]: string | number | undefined | null | boolean };
	}

	interface PendingState {
		[n: string]: string;
	}
	interface RightItemSchema {
		admin: boolean;
		view: boolean;
	}

	interface RightsSchema {
		admin: boolean;
		adsConfig: RightItemSchema;
		adsMonitoring: RightItemSchema;
		adsAdUnits: RightItemSchema;
	}

	interface RolesSchema {
		[n: string]: boolean;
	}

	interface AuthUser {
		id?: string;
		first_name?: string;
		last_name?: string;
		username?: string;
	}
	interface AuthState {
		isChecked: boolean;
		loggedIn: boolean;
		token?: null | string;
		roles: string[];
		user: AuthUser;
	}
	interface User {
		id: string;
		first_name: string;
		last_name: string;
		username: string;
		can_login: boolean;
		roles: string[];
		created_by?: string;
		created_at?: string;
		updated_by?: string;
		updated_at?: string;
		deleted_at?: string;
	}
	interface List {
		id: string;
		name: string;
		disabled?: boolean;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		style?: any;
	}

	interface ListsData {
		campaigns: List[];
		clients: List[];
		measurementTypes: List[];
		salesPersons: List[];
		webs: List[];
	}
	interface ListsState {
		isLoaded: boolean;
		isPending: boolean;
		data: ListsData;
		error?: string;
	}
	interface UsersState {
		isLoaded: boolean;
		isPending: boolean;
		data: User[];
		error?: string;
	}
	interface RightSection {
		admin: boolean;
		reader: boolean;
	}
	interface Rights {
		admin: boolean;
		manager: boolean;
		measurement: RightSection;
	}

	interface AuthInput {
		username?: string;
		password?: string;
	}
	interface NewPasswordInput {
		request_id: null | string;
		password: null | string;
		password_check: null | string;
	}

	interface UserJwtTokenIF extends JwtPayload {
		uid?: string;
		roles?: string[];
		user_info?: AuthUser;
	}
	interface RootState {
		auth: AuthState;
		lists: ListsState;
		measurement: Measurement.State;
		users: UsersState;
		pending: PendingState;
	}

	interface StyleObject {
		[n: string]: string | number;
	}
}
export enum Method {
	Get = 'get',
	Post = 'post',
	Put = 'put',
	Delete = 'delete',
}
export enum AuthorizationRoles {
	Measurement_admin = 'Measurement_admin',
	Measurement_view = 'Measurement_view',
}

export {};
