import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { API } from '@/api';

const state: Measurement.State = {
	isLoaded: false,
	isPending: false,
	data: [],
	error: undefined,
};

export const getters: GetterTree<Measurement.State, RootState> = {
	allData: (state): Measurement[] => state.data,
	isLoaded: (state): boolean => state.isLoaded,
	isPending: (state): boolean => state.isPending,
	error: (state): string | undefined => state.error,
};

export const actions: ActionTree<Measurement.State, RootState> = {
	fetchData: async ({ commit }, force: boolean): Promise<boolean> => {
		if (state.isLoaded && !force) {
			return true;
		}
		commit('setIsPending');
		try {
			const { payload } = await API.measurement.getMeasurements();

			if (Array.isArray(payload)) {
				commit('setData', payload);
				commit('setIsLoaded');
			}

			commit('unsetIsPending');

			return true;
		} catch (errorMessage) {
			commit('setError', errorMessage || 'Neznámá chyba při načtení měření');
			commit('unsetIsPending');

			return true;
		}
	},
};

export const mutations: MutationTree<Measurement.State> = {
	setData(state, data: Measurement[]) {
		state.data = data;
	},
	unsetData(state) {
		state.data = [];
	},
	setIsPending(state) {
		state.isPending = true;
	},
	unsetIsPending(state) {
		state.isPending = false;
	},
	setIsLoaded(state) {
		state.isLoaded = true;
	},
	unsetIsLoaded(state) {
		state.isLoaded = false;
	},
	setError(state, error: string) {
		state.error = error;
	},
	unsetError(state) {
		state.error = undefined;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
