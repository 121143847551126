import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { makeId } from '@/utils';

export const state: PendingState = {};

export const getters: GetterTree<PendingState, RootState> = {
	isOn: (state) => Object.keys(state).length > 0,
};

export const actions: ActionTree<PendingState, RootState> = {
	add: async ({ commit }) => {
		const id = makeId(10, ['char']).toString();
		commit('setPending', id);
		return id;
	},

	remove: ({ commit }, id: string) => {
		commit('unsetPending', id);
	},
};

export const mutations: MutationTree<PendingState> = {
	setPending(state, id: string) {
		state[id] = new Date().toISOString();
	},

	unsetPending(state, id: string) {
		if (state[id]) {
			delete state[id];
		}
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
