import { ajax } from '@/utils';
import { Method } from '@/types';

interface MyAccountDto {
	status: string;
	payload: {
		first_name: string;
		last_name: string;
		email: string;
		roles: string[];
		created_at: string;
	};
}
const myAccount = async (): Promise<MyAccountDto> => ajax<MyAccountDto>(Method.Get, '/my-account');

const changePassword = async (formData: {
	password_old: string;
	password: string;
	password_check: string;
}): Promise<AjaxResponseDto> => ajax<AjaxResponseDto>(Method.Put, '/change-password', formData);

export default {
	myAccount,
	changePassword,
};
