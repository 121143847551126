export interface LocaleCs {
	name: string;
	global: {
		undo: string;
		redo: string;
		confirm: string;
	};
	Popconfirm: {
		positiveText: string;
		negativeText: string;
	};
	Cascader: {
		placeholder: string;
		loading: string;
		loadingRequiredMessage: (label: string) => string;
	};
	Time: {
		dateFormat: string;
		dateTimeFormat: string;
	};
	DatePicker: {
		yearFormat: string;
		monthFormat: string;
		dayFormat: string;
		yearTypeFormat: string;
		monthTypeFormat: string;
		dateFormat: string;
		dateTimeFormat: string;
		quarterFormat: string;
		clear: string;
		now: string;
		confirm: string;
		selectTime: string;
		selectDate: string;
		datePlaceholder: string;
		datetimePlaceholder: string;
		monthPlaceholder: string;
		yearPlaceholder: string;
		quarterPlaceholder: string;
		startDatePlaceholder: string;
		endDatePlaceholder: string;
		startDatetimePlaceholder: string;
		endDatetimePlaceholder: string;
		monthBeforeYear: boolean;
		firstDayOfWeek: 0 | 2 | 1 | 3 | 4 | 5 | 6;
		today: string;
	};
	DataTable: {
		checkTableAll: string;
		uncheckTableAll: string;
		confirm: string;
		clear: string;
	};
	Transfer: {
		sourceTitle: string;
		targetTitle: string;
	};
	Empty: {
		description: string;
	};
	Select: {
		placeholder: string;
	};
	TimePicker: {
		placeholder: string;
		positiveText: string;
		negativeText: string;
		now: string;
	};
	Pagination: {
		goto: string;
		selectionSuffix: string;
	};
	DynamicTags: {
		add: string;
	};
	Log: {
		loading: string;
	};
	Input: {
		placeholder: string;
	};
	InputNumber: {
		placeholder: string;
	};
	DynamicInput: {
		create: string;
	};
	ThemeEditor: {
		title: string;
		clearAllVars: string;
		clearSearch: string;
		filterCompName: string;
		filterVarName: string;
		import: string;
		export: string;
		restore: string;
	};
	Image: {
		tipPrevious: string;
		tipNext: string;
		tipCounterclockwise: string;
		tipClockwise: string;
		tipZoomOut: string;
		tipZoomIn: string;
		tipClose: string;
	};
}

const cs: LocaleCs = {
	name: 'en-US',
	global: {
		undo: 'Zpět',
		redo: 'Znovu',
		confirm: 'Potvrdit',
	},
	Popconfirm: {
		positiveText: 'Confirm',
		negativeText: 'Zrušit',
	},
	Cascader: {
		placeholder: 'Prosím vyberte',
		loading: 'Načítám',
		loadingRequiredMessage: (label: string): string =>
			`Please load all ${label}'s descendants before checking it.`,
	},
	Time: {
		dateFormat: 'dd.MM.yyyy',
		dateTimeFormat: 'dd.MM.yyyy HH:mm:ss',
	},
	DatePicker: {
		yearFormat: 'yyyy',
		monthFormat: 'MMM',
		dayFormat: 'eeeeee',
		yearTypeFormat: 'yyyy',
		monthTypeFormat: 'yyyy-MM',
		dateFormat: 'dd.MM.yyyy',
		dateTimeFormat: 'dd.MM.yyyy HH:mm:ss',
		quarterFormat: 'yyyy-qqq',
		clear: 'Vyčistit',
		now: 'Teď',
		confirm: 'Potvrdit',
		selectTime: 'Vyberte čas',
		selectDate: 'Vyberte datum',
		datePlaceholder: 'Vyberte datum',
		datetimePlaceholder: 'Vyberte datum a čas',
		monthPlaceholder: 'Vyberte měsíc',
		yearPlaceholder: 'Vyberte rok',
		quarterPlaceholder: 'Vyberte čtvrtletí',
		startDatePlaceholder: 'Počáteční datum',
		endDatePlaceholder: 'Koncové datum',
		startDatetimePlaceholder: 'Počáteční datum a čas',
		endDatetimePlaceholder: 'Koncové datum a čas',
		monthBeforeYear: true,
		firstDayOfWeek: 0,
		today: 'Dnes',
	},
	DataTable: {
		checkTableAll: 'Vyberte vše v tabulce',
		uncheckTableAll: 'Zrušit výběr všeho v tabulce',
		confirm: 'Potvrdit',
		clear: 'Vyčistit',
	},
	Transfer: {
		sourceTitle: 'Zdroj',
		targetTitle: 'Cíl',
	},
	Empty: {
		description: 'Bez dat',
	},
	Select: {
		placeholder: 'Prosím vyberte',
	},
	TimePicker: {
		placeholder: 'Vyberte čas',
		positiveText: 'OK',
		negativeText: 'Zrušit',
		now: 'Teď',
	},
	Pagination: {
		goto: 'Příjt na',
		selectionSuffix: 'stránka',
	},
	DynamicTags: {
		add: 'Přidat',
	},
	Log: {
		loading: 'Načítám',
	},
	Input: {
		placeholder: 'Vyplňte',
	},
	InputNumber: {
		placeholder: 'Vyplňte',
	},
	DynamicInput: {
		create: 'Vytvořit',
	},
	ThemeEditor: {
		title: 'Editor vzhledu',
		clearAllVars: 'Vyčistit všechny proměnné',
		clearSearch: 'Vyčistit vyhledávání',
		filterCompName: 'Filtrovat název komponenty',
		filterVarName: 'Filktrovat název proměnné',
		import: 'Import',
		export: 'Export',
		restore: 'Obnovit výchozí',
	},
	// TODO: translation
	Image: {
		tipPrevious: 'Předchozí obrázek (←)',
		tipNext: 'Další obrázek (→)',
		tipCounterclockwise: 'Proti směru hodinových ručiček',
		tipClockwise: 'Ve směru hodinových ručiček',
		tipZoomOut: 'Oddálit',
		tipZoomIn: 'Přiblížit',
		tipClose: 'Zavřít (Esc)',
	},
};
export declare type NLocale = typeof cs;
export default cs;
