import { $fetch } from 'ohmyfetch';
import { BASE_URL } from '@/constants';
import { useAuth } from '@/composables/useAuth';

const createMeasurement = async (body: Measurement.Api.Create.Request) =>
	$fetch<Measurement.Api.Create.Response>(`${BASE_URL}/measurement`, {
		method: 'POST',
		body,
		headers: {
			...useAuth().getAuthHeaders(),
		},
	});

const getMeasurement = async (id: string) =>
	$fetch<Measurement.Api.Detail.Response>(`${BASE_URL}/measurement/${id}`, {
		headers: {
			...useAuth().getAuthHeaders(),
		},
	});

const getMeasurements = async () =>
	$fetch<Measurement.Api.List.Response>(`${BASE_URL}/measurements`, {
		headers: {
			...useAuth().getAuthHeaders(),
		},
	});

const updateMeasurement = async (id: string, body: Measurement.Api.Edit.Request) =>
	$fetch<Measurement.Api.Edit.Response>(`${BASE_URL}/measurement/${id}`, {
		method: 'PUT',
		body,
		headers: {
			...useAuth().getAuthHeaders(),
		},
	});

const pauseMeasurement = async (id: string) =>
	$fetch<Measurement.Api.Pause.Response>(`${BASE_URL}/measurement/${id}/pause`, {
		method: 'POST',
		headers: {
			...useAuth().getAuthHeaders(),
		},
	});

const unpauseMeasurement = async (id: string) =>
	$fetch<Measurement.Api.Unpause.Response>(`${BASE_URL}/measurement/${id}/unpause`, {
		method: 'POST',
		headers: {
			...useAuth().getAuthHeaders(),
		},
	});

const updateMeasurementTrelloId = async (
	id: string,
	trelloId: Measurement.Api.EditTrelloId.Request
) =>
	$fetch<Measurement.Api.EditTrelloId.Response>(`${BASE_URL}/measurement/${id}/trello`, {
		method: 'PUT',
		body: { trello_id: trelloId },
		headers: {
			...useAuth().getAuthHeaders(),
		},
	});

const deleteMeasurement = async (id: string) =>
	$fetch<Measurement.Api.Delete.Response>(`${BASE_URL}/measurement/${id}`, {
		method: 'DELETE',
		headers: {
			...useAuth().getAuthHeaders(),
		},
	});

export default {
	getMeasurement,
	getMeasurements,
	updateMeasurement,
	deleteMeasurement,
	createMeasurement,
	pauseMeasurement,
	unpauseMeasurement,
	updateMeasurementTrelloId,
};
