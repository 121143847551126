type MakeIdType = 'char' | 'charUpper' | 'charLower' | 'number' | 'special' | 'all';

export const makeId = (length: number, type: MakeIdType[]): string | number => {
	let result = '';
	const characters: { [n: string]: string } = {
		charUpper: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
		charLower: 'abcdefghijklmnopqrstuvwxyz',
		special: '!@#$%^&*()-_/+.?',
		number: '0123456789',
		numberOnly: '123456789',
	};
	let finalChars = '';

	type.forEach((option: MakeIdType) => {
		if (option === 'all') {
			finalChars =
				characters.charUpper + characters.charLower + characters.number + characters.specialChars;
		} else if (type.length === 1 && option === 'number') {
			finalChars = characters.numberOnly;
		} else {
			if (option === 'charUpper' && type.indexOf('char') === -1) {
				finalChars += characters.charUpper;
			}
			if (option === 'charLower' && type.indexOf('char') === -1) {
				finalChars += characters.charLower;
			}
			if (
				option === 'char' &&
				type.indexOf('charUpper') === -1 &&
				type.indexOf('charLower') === -1
			) {
				finalChars += characters.char;
			}
			if (option === 'number') {
				finalChars += characters.number;
			}
			if (option === 'special') {
				finalChars += characters.special;
			}
		}
	});

	for (let i = 0; i < length; i += 1) {
		result += finalChars.charAt(Math.floor(Math.random() * finalChars.length));
	}
	if (type.length === 1 && type[0] === 'number') {
		return +result;
	}
	return result;
};
