export const getResponseMessage = (
	ajaxResponse: AjaxResponseDto,
	fallbackMessage?: string,
): string | undefined => {
	if (
		typeof ajaxResponse?.payload === 'object' &&
		ajaxResponse?.payload !== null &&
		'message' in ajaxResponse.payload &&
		typeof ajaxResponse.payload.message === 'string'
	) {
		return ajaxResponse?.payload?.message;
	}

	return fallbackMessage;
};
