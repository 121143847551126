import { ActionTree, GetterTree, MutationTree } from 'vuex';
import jwtDecode from 'jwt-decode';
import { API } from '@/api';
import { TOKEN_NAME } from '@/constants';

const state: AuthState = {
	isChecked: false,
	loggedIn: false,
	token: localStorage.getItem(TOKEN_NAME),
	roles: [],
	user: {
		id: undefined,
		first_name: undefined,
		last_name: undefined,
		username: undefined,
	},
};

export const getters: GetterTree<AuthState, RootState> = {
	isChecked: (state): boolean => state.isChecked,
	isLoggedIn: (state): boolean => state.loggedIn,
	roles: (state): string[] => state.roles,
	rights: (state): Rights => {
		const { roles } = state;
		const isRoleSet = (roleName: string): boolean => {
			return roles.indexOf(roleName) > -1;
		};
		const isAdmin = (): boolean => isRoleSet('admin');
		const isManager = (): boolean => isRoleSet('manager');
		return {
			admin: isAdmin(),
			manager: isManager(),
			measurement: {
				admin: isAdmin() || isManager() || isRoleSet('measurement_admin'),
				reader: isAdmin() || isManager() || isRoleSet('measurement_reader'),
			},
		};
	},
	token: (state) => state.token,
	userInfo: (state) => state.user,
};

export const actions: ActionTree<AuthState, RootState> = {
	login: async ({ commit, dispatch }, token): Promise<void> => {
		localStorage.setItem(TOKEN_NAME, token);
		commit('setToken', token);
		await dispatch('getInfoFromToken');
		commit('setIsChecked');
		commit('setLoggedIn');
	},

	getInfoFromToken: async ({ commit, state }): Promise<void> => {
		let roles: string[] = [];
		const user: AuthUser = {
			id: undefined,
			first_name: undefined,
			last_name: undefined,
			username: undefined,
		};

		if (state.token) {
			const decodedToken: UserJwtTokenIF = jwtDecode(state.token);
			if (typeof decodedToken === 'object' && decodedToken !== null) {
				if ('roles' in decodedToken && Array.isArray(decodedToken?.roles)) {
					roles = decodedToken.roles || [];
				}
				if (
					'user_info' in decodedToken &&
					typeof decodedToken?.user_info === 'object' &&
					decodedToken.user_info !== null
				) {
					user.id = decodedToken?.user_info?.id;
					user.first_name = decodedToken?.user_info?.first_name;
					user.last_name = decodedToken?.user_info?.last_name;
					user.username = decodedToken?.user_info?.username;
				}
			}
		}
		commit('setRoles', roles);
		commit('setUserInfo', user);
	},

	logout: async ({ commit, dispatch }): Promise<boolean> => {
		const loaderId: string = await dispatch('pending/add', null, { root: true });
		try {
			if (await API.auth.logout()) {
				if (localStorage.getItem(TOKEN_NAME)) {
					localStorage.removeItem(TOKEN_NAME);
				}

				commit('setLoggedOut');
				await dispatch('pending/remove', loaderId, { root: true });
				return true;
			}
			await dispatch('pending/remove', loaderId, { root: true });
			return false;
		} catch (errorMessage) {
			window.$message.error(errorMessage);
			await dispatch('pending/remove', loaderId, { root: true });
			return false;
		}
	},

	checkLogin: async ({ commit, dispatch, state }): Promise<boolean> => {
		if (typeof state.token === 'undefined' || state.token === '') {
			commit('setLoggedOut');
			commit('setIsChecked');
			return false;
		}
		commit('unsetIsChecked');
		const loaderId: string = await dispatch('pending/add', null, { root: true });

		try {
			if (await API.auth.checkLogin()) {
				commit('setLoggedIn');
				commit('setToken', localStorage.getItem(TOKEN_NAME));
				await dispatch('getInfoFromToken');
				await dispatch('pending/remove', loaderId, { root: true });
				commit('setIsChecked');
				return true;
			}

			commit('setLoggedOut');
			await dispatch('pending/remove', loaderId, { root: true });
			commit('setIsChecked');
			return false;
		} catch (catchError) {
			commit('setLoggedOut');
			commit('setIsChecked');
			await dispatch('pending/remove', loaderId, { root: true });
			return false;
		}
	},
};

export const mutations: MutationTree<AuthState> = {
	setUserInfo(state, user: AuthUser) {
		state.user = user;
	},
	setRoles(state, roles) {
		state.roles = roles;
	},
	unsetRoles(state) {
		state.roles = [];
	},
	setToken(state, token: string) {
		state.token = token;
	},
	unsetToken(state) {
		state.token = undefined;
		if (localStorage.getItem(TOKEN_NAME)) {
			localStorage.removeItem(TOKEN_NAME);
		}
	},
	setLoggedIn(state) {
		state.loggedIn = true;
	},
	setIsChecked(state) {
		state.isChecked = true;
	},
	unsetIsChecked(state) {
		state.isChecked = false;
	},

	setLoggedOut(state) {
		state.token = undefined;
		state.loggedIn = false;
		if (localStorage.getItem(TOKEN_NAME)) {
			localStorage.removeItem(TOKEN_NAME);
		}
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
